import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import styles from './DropDown.module.scss';

type DynamicObject = {
  [key: string]: any;
};

type DropdownProps = {
  items: DynamicObject[];
  label?: string;
  action?: React.MouseEventHandler<HTMLLIElement> | undefined
};

const Dropdown: React.FC<DropdownProps> = ({ items, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!dropdownRef.current) return;

    const elementUnderCursor = document.elementFromPoint(e.clientX, e.clientY);

    if (dropdownRef.current.contains(elementUnderCursor)) {
      return;
    }

    const rect = dropdownRef.current.getBoundingClientRect();

    const distanceLeft = rect.left - e.clientX;
    const distanceRight = e.clientX - rect.right;

    const distanceTop = rect.top - e.clientY;
    const distanceBottom = e.clientY - rect.bottom;

    if (distanceLeft > 20 || distanceRight > 200 || distanceTop > 10 || distanceBottom > 150) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("mousemove", handleMouseMove);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isOpen]);

  return (
    <div ref={dropdownRef} className={styles.dropdown} onClick={toggleDropdown}>
      {label && <label className={`${styles.dropdown_label} d-flex gap-1 align-items-center`}>{label}
      <span className={`${styles.dropdown_arrow} ${isOpen ? styles.open : ''}`}>&#9660;</span>
      </label>}
      {isOpen && (
        <ul className={styles.dropdown_list}>
          {items.map((item, index) => (
            <Link to={item.address}>
              <li key={index} className={styles.dropdown_item} onClick={() => handleItemClick(item.title)}>
                {item.title}
              </li>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;