import React, {useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useForm } from '@formspree/react';
import { useElement } from '../../utils/ElementContext';
import Popup from "../../components/popup/Popup.jsx";

import { Validate } from "./validate"
import cn from "classnames"
import styles from "./Contact.module.scss"
import Input from "../../customElements/input/input"
import Button from "customElements/button/button";
import Icon from "customElements/icon/icon";

import linkedIn from "../../assets/media/linkedIn.svg"
import mediaIcon from "../../assets/media/clutch.svg"
import layer from "../../assets/media/Layer.svg"


interface FormError {
  error: boolean;
  message: string;
}
interface FormErrors {
  fullName: FormError;
  email: FormError;
  message: FormError;
  privacy: FormError;
}

interface FormState {
  fullName: string;
  email: string;
  phone: string;
  message: string;
  privacy: boolean;
}

export const Contact: React.FC = () => {
  const elementRef = useElement();
  
  const initForm = {
    fullName: "",
    email: "",
    phone: "",
    message: "",
    privacy: false
  }

  const initErrors: FormErrors = {
    fullName: { error: true, message: '' },
    email: { error: true, message: '' },
    message: {error: true, message: '' },
    privacy: {error: true, message: '' },
  }

  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<FormErrors>(initErrors);
  const [state, handleSubmit] = useForm("xqkrjnod");
  const [isButtonactive, setButtonActive ] = useState(true)

  const createData = useRef<FormState>(initForm);

  const validateInput = (value: string | boolean, fieldName: keyof FormErrors) => {
    const error = formErrors[fieldName];
    
    (createData.current[fieldName] as (string | boolean)) = value;
    Validate(value, fieldName, error);
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: error }));
  };

  useEffect(() => {
    const isfilled = (Object.keys(createData.current) as (keyof FormState)[]).every((item) => createData.current[item] === "" || createData.current[item] === false )
    !isfilled && setButtonActive(!Object.values(formErrors).some(item => item.error))
  }, [formErrors])

  useEffect(() => {
    if (state.succeeded) {
      createData.current = initForm
      setFormErrors(initErrors)
    }
  }, [state.result])
  
  const validateAllInputes = (e: any) => {
    e.preventDefault();
    (Object.keys(formErrors) as (keyof FormErrors)[]).forEach((field) => {
      const error = _.cloneDeep(formErrors[field]);
      Validate(createData.current[field], field, error);
      setFormErrors((prevState) => ({ ...prevState, [field]: error }));
      const isfilledAllInputes = (Object.keys(formErrors) as (keyof FormErrors)[]).every((item) => formErrors[item].error === false )
      if(isfilledAllInputes) {
        handleSubmit(e);
        setButtonActive(true)
      } else {
        setButtonActive(false)
      }
    })
  }
  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.href = "mailto:info@armara.dev";
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:+37494491180";
  };

  return (
      <section ref={elementRef} className={cn(styles.component, "contentPositionerVertical")} data-scroll-section="/contact" id="/contact" >
        <div className={`contentPositioner`}>
             <div className={`${styles.main} row`}>
                  <div className={`${styles.main_info} col-xl-4 d-flex flex-column gap-4`}>
                      <span>Contact Us </span>
                      <span>We're here to discuss your mobile app ideas!</span>
                      <div className="d-flex gap-2 align-items-start">
                        <a href="https://www.linkedin.com/company/armara-mobile-development/" target="_blank">
                          <Icon img={linkedIn} width="24px" height="60px" />
                        </a>
                        <a href="https://clutch.co/profile/armara#highlights" target="_blank">
                          <Icon img={mediaIcon} width="28px"/>
                        </a>
                      </div>
                      <div className="">
                        <ul className="d-flex flex-column gap-3" >
                          <li><span>Address: 22 Lusinyanc, Yerevan</span></li>
                          <li>
                            <a onClick={handlePhoneClick}>
                              <span>Phone: +374 94 49 11 80</span>
                            </a>
                          </li>
                          <li>
                            <a onClick={handleEmailClick}>
                              <span>Email: info@armara.dev</span>
                            </a>
                            </li>
                        </ul>
                      </div>
                  </div>
                  <div className={`${styles.main_layer} col-xl-3 d-flex align-items-end`}>
                      <img src={layer} />
                  </div>
                  <div className={`${styles.main_form} position-relative mt-md-3 mt-4 col-xl-5 d-flex flex-column gap-3`}>
                    <form className="d-flex flex-column gap-3" 
                          onSubmit={(e) => validateAllInputes(e)}>
                      <div className="w-100 d-flex flex-sm-row flex-column justify-content-between gap-3">
                        <Input
                            name="name"
                            id="name"
                            width="100%"
                            backGround="rgba(250, 250, 250, 0.09)"
                            action={(e) => validateInput(e.target.value, 'fullName')}
                            placeholder="Name" 
                            errorMessage={formErrors.fullName.message} 
                            error={!isButtonactive && formErrors?.fullName?.error}
                            value={createData.current.fullName}
                        />
                        <Input 
                            name="email"
                            width="100%"
                            backGround="rgba(250, 250, 250, 0.09)"
                            placeholder="Email" 
                            error={!isButtonactive && formErrors?.email?.error} 
                            action={(e) => validateInput(e.target.value, 'email')}
                            errorMessage={formErrors.email.message}
                            value={createData.current.email}
                        />
                      </div>
                        
                      <Input 
                          name="phone"
                          backGround="rgba(250, 250, 250, 0.09)"
                          placeholder="Phone"
                          action={(e) => (createData.current['phone']) = e.target.value}
                          // value={createData.current.phone}
                        />
                      <Input 
                          name="message"
                          backGround="rgba(250, 250, 250, 0.09)"
                          textarea width="100%"
                          placeholder="Message"
                          areaAction={(e) => validateInput(e.target.value, 'message')}
                          error={!isButtonactive && formErrors.message.error}
                          errorMessage={formErrors.message.message}
                          value={createData.current.message}
                        />
                        {/* disabled={state.submitting || isButtonactive === false}  */}
                        <Button sm secondary type="submit" >Submit</Button>
                      <div className={`d-flex flex-column gap-2`}>
                        <div className={`${styles.main_form_privacy} d-flex gap-2 align-items-end`}>
                          <Input checkbox id="flexCheckDefault" 
                                 actionCheckBox={(e) => validateInput(e.currentTarget.checked, 'privacy')}
                                 error={!isButtonactive && formErrors.privacy.error}
                                 checked={createData.current.privacy}
                                //  errorMessage={formErrors.privacy.message}
                                /> 
                          <span> By sending this I confirm that I have read and accept Armara <a onClick={() => navigate('/privacy')}> Privacy Policy </a></span>
                        </div>
                        <span className={`${styles.privacy_error}`}> {formErrors.privacy.message} </span>
                      </div>
                    </form>
                    {state.succeeded && 
                      <Popup isOpen={state.succeeded} title='Thank You' text = 'We will contact you soon' onClose={() => console.log("close")} />
                    }
                  </div>
             </div>
        </div>
      </section>
  )
}
