import email from "../../assets/media/email-icon-1.png";
import phone from "../../assets/media/phone-icon-png--clipart-best-17.png";
import { AppRoutes } from "../../utils/constants";
import styles from "./Footer.module.scss";
import Logo from "assets/media/footerLogo.svg";
import cn from "classnames";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const COPYRIGHT_YEAR = new Date().getFullYear();

export const Footer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;


  const services = [{link: 'Android App development', route: 'services/android'}, 
                    {link: 'iOS App development', route: 'services/ios'},
                    {link: 'Cross-platform App development', route: 'services/cross'},
                    {link: 'MVP development', route: 'services/mvp'},
                    {link: 'Mobile App redevelopment', route: 'services/redevelopment'},
                    {link: 'Mobile App Support', route: 'services/support'},
                  ]

  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.href = "mailto:info@armara.dev";
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:+37494491180";
    window.location.href = "tel:+37494491180";
  };

  return (
    <footer className={cn(styles.component)}>
      <div className={`pt-4 mx-md-5 mx-4 px-0 px-md-4`}>
        <div className={`${styles.main}`}>
          <div
            className={`${styles.main_content} row d-flex flex-xl-row flex-column gap-xl-0 gap-5`}
          >
            <div
              className={`${styles.main_content_media} col-xl-6 d-flex flex-column gap-4`}
            >
              <img src={Logo} alt="Footer Logo" />
              <span>At Armara, we redefine mobile app development with a personalized approach to every project. Choose Armara to craft unique, user-friendly mobile applications that stand out in the market.</span>
            </div>
            <div
              className={`${styles.main_content_info} row col-xl-6 d-flex gap-md-0 gap-3`}
            >
              <div className="col-md-4">
                <p>Services</p>
                <ul className="d-flex flex-column gap-3">
                  {services.map(({ link, route }) => (
                    <li>
                      <Link to={route}>{link}</Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-md-4">
                <p>Quick Links</p>
                <ul className="d-flex flex-column gap-3">
                  <li>
                  {currentPath !== '/' ? 
                   <a onClick={() => navigate('/', { state: { elementRef: '/about-us' } })}>
                    About Us
                   </a> 
                   :
                    <Link
                      to={AppRoutes.AboutUs}
                    >
                      About Us
                    </Link>
                  }
                  </li>
                  <li>
                    <a onClick={() => navigate("/privacy")}> Privacy Policy </a>
                  </li>
                </ul>
              </div>

              <div className="col-md-4">
                <p>Contact</p>
                <ul className="d-flex flex-column gap-3" >
                  <li><span>Address: 22 Lusinyanc, Yerevan</span></li>
                  <li>
                    <a onClick={handlePhoneClick}>
                      <span>Phone: +374 94 49 11 80</span>
                    </a>
                  </li>
                  <li>
                    <a onClick={handleEmailClick}>
                      <span>
                        Email: info@armara.dev
                      </span>
                    </a>
                    </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.main_copyright}>
            <span>
              Copyright ©{COPYRIGHT_YEAR} Armara. All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
